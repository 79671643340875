<template>
  <div>
    <p v-if="err">{{ err }}</p>
    <div v-if="rezept">
      <v-speed-dial
        v-model="fab"
        fixed
        bottom
        right
        v-if="login"
        elevation="5"
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn :x-large="$vuetify.breakpoint.lgAndUp" v-model="fab" color="primary" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-btn
          fab
          dark
          small
          color="green"
          @click="$router.push('/new?RezeptId=' + rezept.id)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="upload = true" fab dark small color="indigo">
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn @click="dialog = true" fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-speed-dial>

      <v-row class="pt-15 d-flex justify-center">
        <h1 style="word-break: normal; text-align: center" class="mx-4">
          {{ rezept.Name }}
        </h1>
      </v-row>
      <v-row class="pb-5 d-flex justify-center">
        <v-rating
          v-if="rezept.Bewertung != 0"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-star-half"
          hover
          small
          half-increments
          readonly
          length="5"
          v-model="rezept.Bewertung"
        ></v-rating>
      </v-row>

      <v-card
        tile
        class="mb-7 mt-10"
        v-if="titleImage != undefined"
        elevation="2"
      >
        <v-img :src="'/bild/' + titleImage.id + '/thumb'"></v-img>
      </v-card>
      <v-row class="justify-center mb-5">
        <span class="grey--text" v-if="rezept.ZubereitungsZeitAnzeige">
          {{ rezept.ZubereitungsZeitAnzeige }}
        </span>
        <v-divider
          v-if="rezept.Portionen"
          inset
          class="mx-4 mt-n1"
          vertical
        ></v-divider>
        <span class="grey--text" v-if="rezept.Portionen">
          {{ rezept.Portionen }}
        </span>
        <v-divider
          v-if="rezept.LetztesMalGemacht"
          inset
          class="mx-4 mt-n1"
          vertical
        ></v-divider>
        <span class="grey--text" v-if="rezept.LetztesMalGemacht">
          {{ rezept.LetztesMalGemacht }}
        </span>
        <v-divider
          v-if="rezept.Niveau"
          inset
          class="mx-4 mt-n1"
          vertical
        ></v-divider>
        <span class="grey--text" v-if="rezept.Niveau">
          Niveau: {{ rezept.Niveau }}
        </span>
        <v-divider
          v-if="login && (!rezept.IsOnline || !rezept.Revised)"
          inset
          class="mx-3 mt-n1"
          vertical
        ></v-divider>
        <v-icon class="red--text mr-2" v-if="login && !rezept.IsOnline" size="18px">
              {{ "mdi-circle" }}
        </v-icon>
        <v-icon class="red--text" v-if="login && !rezept.Revised" size="18px">
              {{ "mdi-pencil" }}
        </v-icon>
      </v-row>

      <v-row>
        <!-- Linke Spalte für Zutaten etc. -->
        <v-col cols="12" md="3" sm="6">
          <div class="pb-3">
            <h2>Zutaten</h2>
          </div>
          <div class="pb-3 pr-3">
            <div v-html="rezept.ZutatenAnzeige"></div>
          </div>

          <div v-if="rezept.tools" class="pb-3">
            <h2>Utensilien</h2>
          </div>
          <div class="pb-3 pr-3">
            <div v-html="rezept.tools"></div>
          </div>

          <div class="pa-1">
            <v-chip-group column>
              <v-chip
                color="black"
                outlined
                disabled
                pill
                v-for="gerichttyp in rezept.Gerichttypen"
                :key="gerichttyp.id"
              >
                {{ gerichttyp.Name }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-col>

        <!-- Rechte Spalte für Zubereitung etc. -->
        <v-col cols="12" md="9" sm="6">
          <div v-if="rezept.Zubereitung" class="pb-4">
            <h2>Zubereitung</h2>
          </div>
          <div v-html="rezept.Zubereitung"></div>

          <div v-if="rezept.Comment && login" class="pb-4">
            <h2>Kommentare</h2>
          </div>
          <div v-if="rezept.Comment && login" v-html="rezept.Comment"></div>

          <SingleRecipeImages
            @ladeBilder="ladeBilder"
            :bilder="this.bilder"
            :rezept="this.rezept"
          />

          <v-dialog v-model="upload" width="500">
            <v-card>
              <v-card-title>
                Bild hochladen
                <v-spacer></v-spacer>
                <v-btn icon @click="upload = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <ImageUpload @save="gespeichert" :RezeptId="rezept.id" />
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Wirklich löschen?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Nein </v-btn>
          <v-btn text @click="deleteRezept"> Ja </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SingleRecipeImages from "@/components/SingleRecipeImages.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import http from "../http-common";
export default {
  name: "EinzelnesRezept",
  components: {
    SingleRecipeImages,
    ImageUpload,
  },
  data() {
    return {
      rezept: null,
      upload: false,
      dialog: false,
      fab: false,
      bilder: [],
      titleImage: null,
      err: null,
      login: "",
    };
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
    ladeRezept() {
      http.get(`/rezept/${this.$route.params.id}`).then((res) => {
        this.rezept = res.data;
        this.formateDatum();
        this.err = null;
        document.title = this.rezept.Name;
      });
    },
    formateDatum() {
      if (this.rezept.LetztesMalGemacht != null) {
        var year = this.rezept.LetztesMalGemacht.substring(0, 4);
        var month = this.rezept.LetztesMalGemacht.substring(5, 7);
        var day = this.rezept.LetztesMalGemacht.substring(8, 10);
        this.rezept.LetztesMalGemacht = day + "." + month + "." + year;
      }
    },
    gespeichert() {
      this.ladeBilder();
      this.upload = false;
    },
    ladeBilder() {
      http.get(`/rezept/${this.$route.params.id}/bild`).then((res) => {
        this.bilder = res.data;
        this.titleImage = res.data.find((image) => image.isTitleImage == 1);
      });
    },
    deleteRezept() {
      this.dialog = false;
      if (this.rezept.id != null) {
        http
          .delete(`/rezept/${this.rezept.id}`)
          .then(() => {
            this.$router.push("/");
          })
      }
    }
  },
  mounted() {
    this.ladeRezept();
    this.ladeBilder();
    this.checkLogin();
  },
};
</script>