<template>
  <v-hover v-slot="{ hover }" close-delay="150">
    <v-expand-transition>
      <v-card
        :elevation="hover ? 8 : 0"
        :class="{ 'on-hover': hover }"
        :to="`${recipe.id}`"
        hover
      >
        <v-img
          v-if="recipe.Bild[0] != undefined"
          :src="'/bild/' + recipe.Bild[0].id + '/thumb'"
        >
          <template v-slot:placeholder>
            <v-sheet color="grey lighten-4" class="fill-height">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
        <v-img
          v-else
          :src="require('../assets/recipe_default.jpg')"
        >
          <template v-slot:placeholder>
            <v-sheet color="grey lighten-4" class="fill-height">
              <v-skeleton-loader
                class="mx-auto"
                type="image"
              ></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
        <v-card-subtitle class="mt-n3 d-flex justify-center">{{ recipe.Niveau }} {{ recipe.Niveau ? "|" : "" }} 
          {{ recipe.ZubereitungsZeitAnzeige }} {{ recipe.ZubereitungsZeitAnzeige ? "|" : "" }} 
          <v-icon class="ml-1" small v-if="ids.includes(115)"> mdi-leaf </v-icon> 
          <v-icon class="ml-1" small v-if="ids.includes(116)"> mdi-sprout </v-icon> 
          <v-icon class="ml-1" small v-if="ids.includes(117)"> mdi-cow </v-icon> 
          <v-icon class="ml-1" small v-if="ids.includes(150)"> mdi-fish </v-icon> {{ login && (!recipe.IsOnline || !recipe.Revised) ? "|" : "" }} 
          <v-icon class="ml-1 red--text" small v-if="login && !recipe.IsOnline"> mdi-circle </v-icon>
          <v-icon class="ml-1 red--text" small v-if="login && !recipe.Revised"> mdi-pencil </v-icon>
        </v-card-subtitle>
        <v-card-title
          class="mt-n8 subheading justify-center"
          style="word-break: normal; text-align: center"
        >
          {{ recipe.Name }}
        </v-card-title>
      </v-card>
    </v-expand-transition>
  </v-hover>
</template>

<script>
import http from "../http-common";
export default {
  name: "RecipeCard",
  props: ["recipe"],
  data() {
    return {
      ids: this.recipe.Gerichttypen.map(g=>g.id),
      login: "",
    };
  },
  methods: {
    checkLogin() {
      http.get(`/auth/user`).then((response) => {
        if ("Meisterkoch" == response.data) {
          this.login = response.data;
        }
      });
    },
  },
  mounted() {
    this.checkLogin();
  },
};
</script>
