<template>
  <div>
    <div>
      <v-form ref="form" v-model="valid" :lazy-validation="true">
        <div>
          <div class="py-2">
            <v-text-field
              v-model="rezept.Name"
              color="purple darken-2"
              label="Name"
              :rules="nameRules"
              required
            ></v-text-field>
          </div>

          <p>Zutaten</p>
          <p>Brüche: ½ ⅓ ⅔ ¼ ¾ ⅕ ⅙</p>
          <vue-editor v-model="rezept.ZutatenAnzeige" />

          <p class="mt-8">Tools</p>
          <vue-editor v-model="rezept.tools" />

          <p class="mt-8">Zubereitungsschritte</p>
          <vue-editor v-model="rezept.Zubereitung" />

          <p class="mt-8">Kommentar</p>
          <vue-editor v-model="rezept.Comment" />

          <v-combobox
            v-model="selectedRegions"
            :items="regions"
            :item-text="(item) => item[0]"
            label="Regionen"
            class="mt-5"
            return-object
            multiple
            chips
            deletable-chips
            clearable
            small-chips
          >
          </v-combobox>

          <v-row class="mt-8">
            <v-col>
              <FilterButtons />
            </v-col>
            <v-col>
              <v-text-field
                v-model="rezept.ZubereitungsZeitAnzeige"
                label="Zubereitungszeit"
              ></v-text-field>

              <v-text-field
                v-model="rezept.Portionen"
                label="Portionen"
              ></v-text-field>

              <div class="d-flex pb-5 pt-3">
                <span class="mr-2">Bewertung</span>
                <v-rating
                  empty-icon="mdi-star-outline"
                  full-icon="mdi-star"
                  half-icon="mdi-star-half"
                  hover
                  half-increments
                  clearable
                  length="5"
                  v-model="rezept.Bewertung"
                ></v-rating>
              </div>
              <v-select
                v-model="rezept.Niveau"
                :items="niveaux"
                label="Niveau"
              ></v-select>
              <div>
                <v-switch v-model="rezept.IsOnline" label="Online"></v-switch>
              </div>
              <div>
                <v-switch v-model="rezept.Revised" label="Revised"></v-switch>
              </div>
              <p>Wann hast du das Rezept zuletzt gemacht?</p>
              <v-date-picker v-model="rezept.LetztesMalGemacht"></v-date-picker>
            </v-col>
          </v-row>
        </div>
      </v-form>

      <v-dialog v-model="showTips" width="700">
        <v-card>
          <v-btn icon @click="showTips = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <HowToMarkdown />
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
    <v-btn
      :x-large="$vuetify.breakpoint.lgAndUp"
      fab
      bottom
      color="primary"
      fixed
      elevation="5"
      right
      @click="saveRezept"
    >
      <v-icon> mdi-check </v-icon>
    </v-btn>
  </div>
</template>

<script>
import http from "../http-common";
import FilterButtons from "@/components/FilterButtons.vue";
export default {
  name: "NeuesRezept",
  components: {
    FilterButtons,
  },
  data() {
    return {
      nameRules: [(v) => !!v || "Darf nicht leer sein."],
      valid: true,
      selectedFile: null,
      menu: false,
      showTips: false,
      niveaux: ["simpel", "moderat", "raffiniert"],
      alleGerichttypen: [],
      regions: [],
      selectedRegions: [],
      rezept: {
        id: null,
        Name: "",
        Zubereitung: "",
        ZutatenAnzeige: "",
        LetztesMalGemacht: null,
        ZubereitungsZeitAnzeige: "",
        Portionen: "",
        tools: "",
        Bewertung: 0,
        Niveau: "",
        AnzeigebildId: null,
        Gerichttypen: [],
        IsOnline: true,
        Revised: false,
        Comment: ""
      },
    };
  },
  methods: {
    saveRezept() {
      if (this.$refs.form.validate()) {
        this.rezept.Gerichttypen = this.$store.state.tags.concat(
          this.selectedRegions.map((a) => a[1])
        );
        if (this.rezept.id == null) {
          http.post("/rezept", this.rezept).then((response) => {
            this.$store.commit("changeTags", []);
            this.$store.commit("changeArt", null);
            this.$router.push("/" + response.data.id);
          });
        } else {
          http.put(`/rezept/${this.rezept.id}`, this.rezept).then(() => {
            this.$store.commit("changeTags", []);
            this.$store.commit("changeArt", null);
            this.$router.back();
          });
        }
      }
    },

    newRezept() {
      this.rezept = {};
    },

    getAllGerichttypen() {
      http.get("/gerichttyp").then((response) => {
        this.alleGerichttypen = response.data;
        this.regions = response.data
          .filter((a) => a.Art == 3)
          .map((a) => [a.Name, a.id]);
        this.findRezeptIfAny();
      });
    },

    findRezeptIfAny() {
      if (this.$route.query.RezeptId) {
        http.get(`/rezept/${this.$route.query.RezeptId}`).then((response) => {
          this.rezept = response.data;
          var tags = this.rezept.Gerichttypen.map((a) => a.id);
          this.$store.commit("changeTags", tags);
          if (tags.includes(151)) {
            this.$store.commit("changeArt", 0);
          }
          if (tags.includes(152)) {
            this.$store.commit("changeArt", 1);
          }
          if (tags.includes(153)) {
            this.$store.commit("changeArt", 2);
          }
          this.selectedRegions = this.regions.filter((a) =>
            tags.includes(a[1])
          );
          document.title = this.rezept.Name;
          this.rezept.LetztesMalGemacht =
            this.rezept.LetztesMalGemacht.substring(0, 10);
        });
      } else {
        document.title = "Neues Rezept";
      }
    },
  },
  mounted() {
    this.getAllGerichttypen();
  },
  async created() {
    this.$store.commit("changeTags", []);
    this.$store.commit("changeArt", null);
  },
};
</script>
